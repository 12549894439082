<template>
  <div class="pt-4">
    <div
      v-for="(team, index) in teamMembers.slice(0, length)"
      :key="index"
      class="mb-4"
    >
      <div class="flex items-center">
        <div class="w-20 h-20">
          <img
            class="object-cover w-20 h-20 rounded-full"
            :src="team.image"
            alt=""
          />
        </div>
        <div class="ml-4">
          <p class="text-sm font-semibold">{{ team.name }}</p>
          <p class="font-semibold text-text-alternate-3 mt-3 text-xs">
            {{ team.role }}
          </p>
        </div>
      </div>
      <p class="text-sm font-medium mt-3">{{ team.description }}</p>
      <hr
        class="my-4 border-t border-border-primary"
        v-if="index + 1 < teamMembers.length"
      />
    </div>
    <button
      v-if="teamMembers.length > 3"
      class="
        h-12
        w-full
        rounded
        bg-bg-alternate-3
        text-sm
        font-bold
        text-text-alternate-10
        mt-4
      "
      @click="setLength"
    >
      {{ length == 3 ? 'Show more' : 'Show less' }}
    </button>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue';
const length = ref(3)
const props = defineProps({
  fundraiser: {
    type: Object,
  },
});
const teamMembers = computed(() => {
  return props.fundraiser.teammembers;
});
const setLength = () => {
  if (length.value == 3) {
    length.value = teamMembers.value.length;
  } else {
    length.value = 3;
  }
};
</script>

<style></style>
